<template>
    <div class="df-panels-container df-panels-container-tabs h-full">
        <div class="df-panels-tabs h-full">
            <base-tabs>
                <tab-item title="Components">
                    <div class="df-panels-forms-wrapper w-full h-full">
                        <div class="df-elements-container">
                            <div class="df-elements-top mt-2">
                                <div class="w-full h-full">
                                    <ul class="tabs__header">
                                        <li
                                            v-for="field in fieldsCategories"
                                            :key="field.id"
                                            class="tabs__item"
                                            :class="{ selected: activeElementCategory === field.id }"
                                            @click="activeElementCategory = field.id">
                                            {{ field.label }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="df-elements-wrapper overflow-hidden overflow-y-auto h-[calc(100dvh_-_160px)]">
                                <components-list-item v-for="item in filtereadElements" :key="item.id" :item="item"></components-list-item>
                            </div>
                        </div>
                    </div>
                </tab-item>
                <tab-item title="Tree" class=""> </tab-item>
            </base-tabs>
        </div>
        <div class="flex absolute flex-col df-top-left-tools-container">
            <div class="flex flex-col vfb-tool">
                <!-- <BaseButton></BaseButton> -->
                <div
                    class="flex relative items-center justify-center cursor-pointer vfb-tool-item"
                    :class="{ 'vfb-tool-item__active': toolsActiveItem === 'editor' }"
                    @click="setEditorMode('editor')"
                    v-tooltip="'Editor mode'">
                    <base-icon style="height: 20px" viewBox="0 0 24 24" name="editPencil"></base-icon>
                </div>
                <div
                    class="flex relative items-center justify-center cursor-pointer vfb-tool-item"
                    :class="{ 'vfb-tool-item__active': toolsActiveItem === 'viewer' }"
                    @click="setEditorMode('viewer')"
                    v-tooltip="'Viewer mode'">
                    <base-icon style="height: 20px" viewBox="0 0 24 24" name="eye"></base-icon>
                </div>

                <div
                    class="flex relative items-center justify-center cursor-pointer vfb-tool-item"
                    :class="{ 'vfb-tool-item__active': toolsActiveItem === 'schema' }"
                    @click="setEditorMode('schema')"
                    v-tooltip="'Show schema'">
                    <base-icon style="height: 24px" viewBox="0 0 28 28" name="fieldTypeJson"></base-icon>
                </div>
                <div class="flex relative items-center justify-center cursor-pointer vfb-tool-item" @click="valideAll" v-tooltip="'Validate'">
                    <base-icon style="height: 24px" viewBox="0 0 28 28" name="fieldLock"></base-icon>
                </div>
                <div class="flex relative items-center justify-center cursor-pointer vfb-tool-item" @click="undo" v-tooltip="'Undo'">
                    <base-icon style="height: 24px" viewBox="0 0 28 28" name="schedule"></base-icon>
                </div>
                <!-- <div
          class="flex relative items-center justify-center cursor-pointer vfb-tool-item"
          @click="redo"
          v-tooltip="'Redo'"
        >
          <base-icon
            style="height: 24px"
            viewBox="0 0 28 28"
            name="fieldLock"
          ></base-icon>
        </div> -->
                <div class="flex relative items-center justify-center cursor-pointer vfb-tool-item" @click="showFormModul" v-tooltip="'Form module'">
                    <base-icon style="height: 24px" viewBox="0 0 24 24" name="annotation"></base-icon>
                </div>
                <div class="flex relative items-center justify-center cursor-pointer vfb-tool-item" @click="SaveSchema" v-tooltip="'Save schema'">
                    <base-icon style="height: 24px" viewBox="0 0 24 24" name="savead"></base-icon>
                </div>
                <div class="flex relative items-center justify-center cursor-pointer vfb-tool-item" @click="clearSchema" v-tooltip="'Clear schema'">
                    <base-icon style="height: 24px" viewBox="0 0 24 24" name="delete"></base-icon>
                </div>
                <div v-if="multilingual" class="flex relative items-center justify-center vfb-tool-item cursor-pointer border border-gray-100" v-tooltip="'Change locale'">
                    <BaseSelect
                        :size="32"
                        class="flex w-full h-full"
                        :items="availableLocales"
                        :value="currentLocale"
                        :tags="false"
                        :search="false"
                        :dropdownWidth="80"
                        :multiple="false"
                        :valueTypeObject="false"
                        :object="true"
                        labelProp="title"
                        :dropdownWidthEqual="false"
                        noBorder
                        :clearSingle="false"
                        :hideToggleIcon="true"
                        @change="changeLocale" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentsListItem from '@/components/dynamicForm/components/formBuilder/componentsList/ComponentsListItem.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseTabs from '@/components/ui/tabs/BaseTabs.vue';
import TabItem from '@/components/ui/tabs/TabItem.vue';
import BaseSelect from '@/components/ui/baseSelect/BaseSelect.vue';
// import BaseButton from "uikit"
export default {
    name: 'ComponentsList',
    components: {
        ComponentsListItem,
        BaseIcon,
        BaseTabs,
        TabItem,
        BaseSelect
    },
    props: {
        availableComponents: {
            type: Array
        },
        formSettings: {
            type: Object
        }
    },
    emits: ['setEditorMode', 'showSchema', 'valideAll', 'clearSchema', 'saveSchema', 'showFormModule', 'customAction'],
    data() {
        return {
            activeElementCategory: 'fields',
            toolsActiveItem: 'editor'
        };
    },
    computed: {
        fieldsCategories() {
            return [
                { id: 'fields', label: 'Fields' },
                { id: 'static', label: 'Static' },
                { id: 'structure', label: 'Structure' },
                { id: 'widgets', label: 'Widgets' }
                // {id:'entityFields', label:'Entity fields'},
            ];
        },
        filtereadElements() {
            return this.availableComponents.filter(el => el.category === this.activeElementCategory);
        },
        multilingual() {
            return this.formSettings?.locales?.length > 1;
        },
        currentLocale() {
            return this.formSettings?.locale;
        },
        availableLocales() {
            return this.formSettings?.availableLocales.filter(el => this.formSettings?.locales?.includes(el.id));
        }
    },
    methods: {
        setEditorMode(mode) {
            this.toolsActiveItem = mode;
            this.$emit('setEditorMode', mode);
        },
        valideAll() {
            this.$emit('valideAll');
        },
        setActiveElementCategory(category) {
            console.log('category', category);
        },
        showSchema() {
            this.$emit('showSchema');
        },
        clearSchema() {
            this.$emit('clearSchema');
        },
        SaveSchema(event) {
            this.$emit('saveSchema', event);
        },
        showFormModul() {
            this.$emit('showFormModule');
        },
        undo() {
            this.$emit('customAction', 'undo');
        },
        redo() {
            this.$emit('customAction', 'redo');
        },
        changeLocale(newValue) {
            this.$emit('customAction', 'changeLocale', newValue);
        }
    }
};
</script>

<style scoped lang="scss">
// .df-panels-forms-container {
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
//   overflow-y: auto;
//   overflow-x: hidden;
// }
.df-panels-container-tabs .df-panels-tabs {
    display: flex;
    align-items: center;
    font-size: 0.9375rem;

    .df-panels-tab {
        flex: 1 1 0%;
        cursor: pointer;
        border-bottom-width: 1px;
        border-right-width: 1px;
        --tw-border-opacity: 1;
        border-right-color: rgb(226 232 240 / var(--tw-border-opacity));
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        text-align: center;

        &.is-active {
            --tw-border-opacity: 1;
            border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgb(255 255 255 / var(--tw-bg-opacity));
        }
        &.is-inactive {
            --tw-border-opacity: 1;
            border-bottom-color: rgb(226 232 240 / var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgb(241 245 249 / var(--tw-bg-opacity));
        }
    }
}
.df-panels-forms-wrapper {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 1.5rem;
}
.df-elements-container {
    //   position: absolute;
    //   top: 2.875rem;
    //   bottom: 0px;
    //   left: 0px;
    //   right: 0px;

    // .df-elements-top {
    //   padding: 1rem 1rem 0.5rem;
    // }
    .df-elements-wrapper {
        // position: absolute;
        // top: 6.5625rem;
        // bottom: 0px;
        // left: 0px;
        // right: 0px;
        overflow-y: auto;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}
.df-top-left-tools-container {
    top: 1rem;
    right: -3rem;
    background-color: #fff;

    .vfb-tool {
        border-radius: 0.25rem;
        border-width: 1px;

        .vfb-tool-item {
            height: 32px;
            width: 32px;
        }
        .vfb-tool-item__active {
            background-color: var(--color-modern-primary);
        }
    }
}
</style>
