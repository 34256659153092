<template>
    <div class="datepicker__wrapper w-full relative">
        <p v-if="label" class="datepicker__label">
            {{ label }}
            <span v-if="required" class="text-[#FF7C73]"> * </span>
        </p>
        <div class="datepicker__container-wrapper" :class="{ 'cursor-not-allowed': readonly }">
            <div
                class="datepicker__container cursor-pointer"
                :class="{
                    'pointer-events-none': readonly,
                    'border-[#F9F9F9]': valid,
                    'border-[#FF7C73]': !valid,
                    'cursor-pointer': !readonly,
                    'cursor-default': readonly
                }"
                @click="toggleCalendarModal"
                :style="containerStyle"
                ref="datepickerContainer">
                <input
                    type="text"
                    class="active-date__input pointer-events-none"
                    :placeholder="dateTime && !this.range ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'"
                    :value="inputValue"
                    :disabled="readonly" />

                <div class="max-h-6 shrink-0">
                    <button type="button" data-action="openCalendar" class="w-6 h-6 flex items-center justify-center" :disabled="readonly">
                        <BaseIcon class="button__icon fill-[#C0C2CD] h-5 w-5 hover:fill-[#519DF5]" name="calendarNew"></BaseIcon>
                    </button>
                </div>
            </div>

            <button
                type="button"
                v-if="showResetButton && Boolean(componentValue) && !readonly"
                class="reset__button cursor-pointer w-6 h-6 flex items-center"
                :style="{ bottom: resetButtonPosition}"
                @click="handleReset($event)"
                :disabled="readonly">
                <BaseIcon class="button__icon h-6 w-6" name="close"></BaseIcon>
            </button>
        </div>
        <SmartModal v-if="showCalendarModal" :target="this.$refs.datepickerContainer" @close="closeCalendarModal">
            <div class="datepicker__modal-container flex flex-col">
                <DatePicker v-if="!range" v-model.number="datePickerValue" v-bind="calendarProps" />
                <DatePicker v-if="range" v-model.range.number="datePickerValue" v-bind="calendarProps" />
                <div class="datepicker__confirm-buttons p-4 flex gap-4">
                    <BaseButton color="secondary" @click="closeCalendarModal" :label="$t('buttons.cancel')" class="w-[100px]" />
                    <BaseButton @click="onSave" :label="$t('buttons.save')" class="w-[100px]" />
                </div>
            </div>
        </SmartModal>
    </div>
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon.vue';
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import dayjs from 'dayjs';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import SmartModal from '@/components/ui/SmartModal.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';

export default {
    name: 'BaseDatePicker',
    components: { BaseIcon, DatePicker, SmartModal, BaseButton },
    props: {
        label: String,
        value: [Number, String, Date, Array, Object],
        modelValue: [Number, String, Date, Array, Object],
        readonly: {
            type: Boolean,
            default: false
        },
        showResetButton: {
            type: Boolean,
            default: true
        },
        valid: {
            type: Boolean,
            default: true
        },
        dateTime: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'lg',
            validator: value => ['sm', 'md', 'lg'].includes(value),
        },
        range: {
            type: Boolean,
            default: false
        }
    },
    emit: ['update', 'update:modelValue'],
    data() {
        return {
            componentValue: '',
            datePickerValue: '',
            attrs: [
                {
                    key: 'today',
                    highlight: {
                        color: 'gray',
                        fillMode: 'light'
                    },
                    dates: new Date()
                }
            ],
            modelConfig: {
                type: 'number'
            },
            showCalendarModal: false
        };
    },
    computed: {
        ...mapState(useUserStore, ['user']),
        masks() {
            return { input: this.formatDate };
        },
        formatDate() {
            return this.dateTime && !this.range ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
        },
        currentLanguage() {
            const language = this.user?.lang_id?.slice(0, 2);
            return language ? language : 'en';
        },
        inputValue() {
            if (this.componentValue && Number.isInteger(this.componentValue)) return dayjs(this.componentValue).format(this.formatDate);
            if (typeof this.componentValue === 'object' && this.componentValue !== null && Object.keys(this.componentValue).length) {
                return `${dayjs(this.componentValue.start).format(this.formatDate)} - ${dayjs(this.componentValue.end).format(this.formatDate)}`;
            } else return '';
        },
        calendarMode() {
            return this.dateTime && !this.range ? 'dateTime' : 'date';
        },
        propsValue() {
            return this.value || this.modelValue;
        },
        calendarProps() {
            return {
                mode: this.calendarMode,
                range: this.range,
                isRequired: true,
                is24hr: true,
                locale: this.currentLanguage,
                modelConfig: this.modelConfig,
                firstDayOfWeek: 2,
                masks: { weekdays: 'WW' }
            };
        },
        sizeConfig() {
            const sizes = {
                sm: { height: '32px', borderRadius: '4px' },
                md: { height: '38px', borderRadius: '8px' },
                lg: { height: '44px', borderRadius: '12px' },
            };
            return sizes[this.size];
        },
        containerStyle() {
            return {
                minHeight: this.sizeConfig.height,
                height: this.sizeConfig.height,
                borderRadius: this.sizeConfig.borderRadius,
            };
        },
        resetButtonPosition() {
            return (parseInt(this.sizeConfig.height) - 24) / 2 + 'px';
        },
    },
    watch: {
        propsValue(newVal) {
            if (this.componentValue !== newVal) {
                this.componentValue = newVal || '';
            }
        },

        componentValue(newVal, oldVal) {
            if (newVal !== this.propsValue && newVal !== oldVal) {
                if (this.range) {
                    this.emitInput(this.getValidPeriod(newVal));
                } else {
                    this.emitInput(newVal);
                }
            }
        }
    },
    methods: {
        emitInput(value) {
            this.$emit('update', value);
            this.$emit('update:modelValue', value);
        },
        onSave() {
            this.componentValue = this.datePickerValue;
            this.closeCalendarModal();
        },
        handleReset() {
            // e.preventDefault();
            this.componentValue = null;
        },

        setInitialValue() {
            this.componentValue = this.propsValue || '';
        },
        toggleCalendarModal() {
            if (!this.showCalendarModal) {
                this.datePickerValue = this.componentValue;
            }
            this.showCalendarModal = !this.showCalendarModal;
        },
        closeCalendarModal() {
            this.showCalendarModal = false;
        },

        getValidPeriod(value) {
            if (value?.end) {
                value.end = dayjs(value.end).endOf('day').unix() * 1000;
            }
            return value;
        }
    },
    mounted() {},
    created() {
        this.setInitialValue();
    }
};
</script>

<style lang="scss" scoped>
.datepicker__wrapper {
    &:hover .reset__button {
        display: flex;
    }
}
.datepicker__label {
    @apply text-sm font-normal text-[#131416] mb-0.5;
}
.datepicker__container {
    @apply flex items-center relative pr-4  border border-[##F9F9F9] focus-within:border-[#519DF5] hover:border-[#979BB2]  rounded-xl gap-1 bg-[#F9F9F9];
    &:focus-within {
        border-color: #519df5;
    }
}
.datepicker__container-wrapper {
    @apply relative;
}
.datepicker-block {
    @apply flex flex-col;
    &__label {
        @apply text-[12px] text-[#A1A2A4];
    }
    &__input {
        @apply text-[14px] text-[#2E373E];
    }
}
.datepicker-wrapper .vc-popover-caret {
    visibility: hidden;
}
.active-date__input {
    @apply bg-white w-full font-normal text-[#141414] pl-4 pr-2 rounded-xl disabled:text-[#979BB2] bg-inherit;
}
.reset__button {
    position: absolute;

    right: 40px;
    color: #c0c2cd;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &:hover {
        color: #519df5;
    }
}
</style>
