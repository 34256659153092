<template>
    <div class="df-element-container overflow-hidden" draggable="true" tabindex="-1" role="options" @dragstart="startDrag($event, item)">
        <div class="df-element-icon-container">
            <!-- <svg class="df-icon df-element-icon"></svg> -->
            <base-icon class="df-icon df-element-icon" :name="item.icon" viewBox="0 0 28 28"></base-icon>
        </div>
        <div class="df-element-text-container">
            <div class="df-element-text-label truncate">{{ item.label }}</div>
            <div class="df-element-text-description truncate">{{ item.description }}</div>
        </div>
    </div>
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon.vue';

export default {
    name: 'ComponentsListItem',
    components: { BaseIcon },
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {};
    },
    methods: {
        startDrag(event, item) {
            event.dataTransfer.dropEffect = 'move';
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.setData('createItemId', item.id);
        }
    }
};
</script>

<style scoped lang="scss">
.df-element-container {
    margin: 0.5rem -0.625rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    // border-width: 1px;
    padding: 0.625rem;
    cursor: pointer;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.3s;

    &:hover {
        --tw-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
        --tw-shadow-colored: 0px 0px 10px 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .df-element-icon-container {
        margin-right: 0.75rem;
        display: flex;
        height: 2rem;
        width: 2rem;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        background-color: rgb(241 245 249);
        font-size: 0.8125rem;
        line-height: 1.125rem;
        color: rgb(100 116 139);

        .df-element-icon {
            max-height: 1.25rem;
            max-width: 1.25rem;
        }
    }
    .df-element-text-container {
        .df-element-text-label {
            font-weight: 500;
            line-height: 16px;
            font-size: 14px;
            color: rgb(30 41 59);
        }
        .df-element-text-description {
            font-size: 0.8125rem;
            line-height: 1.375;
            color: rgb(100 116 139);
        }
    }
}
</style>
