<template>
    <span
        class="platform-badge flex shrink-0 items-center justify-center text-[#FFFFFF] rounded-full px-0.5 cursor-default select-none"
        :style="[sizeToPx(props.size), colorToBackground(props.color)]">
        <slot></slot>
    </span>
</template>
<script setup>
const props = defineProps({
    size: {
        type: String,
        default: 'md',
        validator: prop => ['sm', 'md'].includes(prop)
    },
    color: {
        type: String,
        default: 'primary',
        validator: prop => ['primary', 'danger', 'success', 'warning'].includes(prop)
    }
});

const sizeToPx = size => {
    switch (size) {
        case 'sm':
            return {
                minWidth: '16px',
                minHeight: '16px',
                height: '16px',
                fontSize: '10px',
                fontWeight: 700,
                lineHeight: '28px'
            };
        case 'md':
            return {
                minWidth: '24px',
                minHeight: '24px',
                height: '24px',
                fontSize: '13px',
                fontWeight: 700,
                lineHeight: '15.73px'
            };
        default:
            return { minWidth: '16px', minHeight: '16px' };
    }
};
const colorToBackground = size => {
    switch (size) {
        case 'primary':
            return {
                background: '#519DF5'
            };
        case 'danger':
            return {
                background: '#FF7C73'
            };
        case 'success':
            return {
                background: '#55C47B'
            };
        case 'warning':
            return {
                background: '#FBC12E'
            };
        default:
            return {
                background: '#519DF5'
            };
    }
};
</script>

<style lang="scss" scoped></style>
