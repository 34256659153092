import { sendRequest } from '@/helpers';

export default {
    async getEntitys() {
        let res = await sendRequest(`/api/entities`, 'get', '');
        return res;
    },
    async getInstance(entitiId, instanceId) {
        if (!entitiId) {
            throw new Error(`no entitiId ${entitiId}`);
        }
        if (!instanceId) {
            throw new Error(`no instanceId ${instanceId}`);
        }
        return await sendRequest(`/api/instance/${entitiId}/${instanceId}`, 'get', '');
    },
    async getEntity(id) {
        let res = await sendRequest(`/api/entity/${id}`, 'get', '');
        return res;
    },
    async createEntity(entity) {
        let res = await sendRequest(`/api/entity/`, 'post', entity);
        return res;
    },
    async getEntityFields(entitiId) {
        let res = await sendRequest(`/api/entity_props/${entitiId}`, 'get', '');
        return res;
    },
    async getEntityAvailableFields() {
        let res = await sendRequest(`/api/custom_entity_fields/get_fields_types`, 'get', '');
        return res;
    },
    async getEntityData(entitiId, param = '') {
        let res = await sendRequest(`/api/instances/${entitiId}${param}`, 'get', '');
        return res;
    },
    async getEntityField(fieldId) {
        return await sendRequest(`/api/custom_entity_field/${fieldId}`, 'get', '');
    },
    async createEntityFields(field) {
        let res = await sendRequest(`/api/custom_entity_field/`, 'post', field);
        return res;
    },
    async updateEntityFields(fieldId, data) {
        let res = await sendRequest(`/api/custom_entity_field/${fieldId}`, 'put', data);
        return res;
    },
    async deleteEntityFields(fieldId) {
        let res = await sendRequest(`/api/custom_entity_field/${fieldId}`, 'delete', '');
        return res;
    },
    async getEntityForms(entitiId) {
        return await sendRequest(`api/form_views/${entitiId}`, 'get', '');
    },
    async getEntityFormById(formId) {
        return await sendRequest(`api/form_view/${formId}`, 'get', '');
    },
    async deleteEntity(entitiId) {
        let res = await sendRequest(`/api/entity/${entitiId}`, 'delete', '');
        return res;
    },
    //// Instances
    async globalSearch(query, params) {
        return await sendRequest(`/api/instances/global_search`, 'post', {
            query: query,
            entities: params.entities,
            fields: params.fields
        });
    },
    async participantsSearch(crmAccountId, query, params) {
        return await sendRequest(`/api/crm/activities_participants_search?crm_account_id=${crmAccountId}&query=${query}`, 'post', {
            fields: params.fields
        });
    },

    async getEntityInstances(entityId) {
        return await sendRequest(`/api/instances/${entityId}`, 'get', {}, null, true);
    },
    async getEntityInstancesVsParams(entityId, params, showLoader = true) {
        return await sendRequest(`/api/instances/search/${entityId}`, 'post', params, null, showLoader);
    },
    async getEntityInstanceById(entityId, instanceId) {
        if (!entityId) {
            throw new Error(`no entitiId ${entityId}`);
        }
        if (!instanceId) {
            throw new Error(`no instanceId ${instanceId}`);
        }
        let res = await sendRequest(`/api/instance/${entityId}/${instanceId}`, 'get', '', null, true);
        return res;
    },
    async createEntityInstances(entityId, data) {
        let res = await sendRequest(`/api/instance/${entityId}/`, 'post', data);
        return res;
    },
    async updateEntityInstances(entityId, instanceId, data) {
        let res = await sendRequest(`/api/instance/${entityId}/${instanceId}`, 'put', data);
        return res;
    },
    async deleteEntityInstances(entityId, instanceId) {
        let res = await sendRequest(`/api/instance/${entityId}/${instanceId}`, 'delete', '');
        return res;
    },
    async getInstancesCount(entityId, params, showLoader = false) {
        return await sendRequest(`api/instances/count/search/${entityId}`, 'post', params, null, showLoader, entityId);
    },

    async getSubInstances(entityId, instanceId, subEntityId) {
        // subEntityId = CRMActivities або CRMSales
        let res = await sendRequest(`/api/sub_instances/${entityId}/${instanceId}/${subEntityId}`, 'post', '');
        return res;
    },
    async entityBatch(entityId, params) {
        let res = await sendRequest(`/api/instances/batch/${entityId}`, 'post', params);
        return res;
    },
    async entityBulkOperation(params) {
        let res = await sendRequest(`/api/instances/bulk_operation`, 'post', params);
        return res;
    },
    async entityBatchUpdate(entityId, params) {
        let res = await sendRequest(`/api/instances/batch/${entityId}`, 'put', params);
        return res;
    },
    async entityBatchDelete(entityId, params) {
        let res = await sendRequest(`/api/instances/batch/${entityId}`, 'delete', params);
        return res;
    },

    //// Form views////
    async getFormViewByParameter(entityId, formType, parameter) {
        return await sendRequest(`/api/form_views/${entityId}/${formType}/${parameter}`, 'get', '');
    },
    async getFormViewByFormType(entityId, formType) {
        return await sendRequest(`/api/form_views/${entityId}/${formType}`, 'get', '');
    },
    async getFormViewByEntityId(entityId) {
        return await sendRequest(`/api/form_views/${entityId}`, 'get', '');
    },
    async saveFormView(data) {
        return await sendRequest(`api/form_view/`, 'post', data);
    },
    async updateFormView(viewId, data) {
        return await sendRequest(`api/form_view/${viewId}`, 'put', data);
    },
    async deleteFormView(viewId) {
        return await sendRequest(`api/form_view/${viewId}`, 'delete');
    },
    async getEntityFormViews(entityId) {
        return await sendRequest(`api/form_views/${entityId}`, 'get', '');
    },

    async getFrequentlyUsedValues(entityId, fieldName) {
        return await sendRequest(`api/entity_selected_values/${entityId}/${fieldName || ''}`, 'get', '');
    },

    async findDuplicates(data) {
        return await sendRequest(`/api/instances/find_duplicates`, 'post', data);
    },
    async sendContext(data) {
        return await sendRequest(`/api/sockets/subscribe`, 'post', data);
    },
    async getSubordinates(type = 'crm') {
        return await sendRequest(`/api/users/subordinates?subordinates_type=${type}`, 'get', '');
    }
};
