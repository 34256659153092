<template>
    <div
        class="sp-input relative group/input"
        :class="{
            'sp-input_has-value': internalValue,
            'has-icon': iconName,
            focused: focused,
            'in-line': inLine,
            'space-between': spaceBetween,
            'has-label': labelName,
            'no-border': noBorder
        }">
        <label
            class="sp-input__label relative flex relative w-full h-full"
            :style="{
                color: labelFontColor,
                fontSize: labelFontSize,
                fontWeight: labelFontWeight
            }">
            <div v-if="noBorder" :class="[{ 'sp-input__label-noborder': noBorder }]">{{ labelName }}<span v-if="required" class="text-[#FF7C73]">*</span></div>
            <div :class="[{ invisible: noBorder }]">{{ labelName }}<span v-if="required" class="text-[#FF7C73]">*</span></div>
            <div
                class="sp-input__wrapper relative w-full h-full text-[14px] flex items-center gap-1 transition-[border-color] ease-out text-[#131416]"
                :class="[
                    {
                        'px-4 rounded-[12px] bg-[#FFFFFF] border': !noBorder
                    },
                    {
                        'border-y-2 border-t-transparent bg-transparent': noBorder
                    },
                    { 'border-[#FF7C73]': !valid || isInvalidInteger },

                    {
                        'border-[#519df5]': focused && valid
                    },
                    {
                        'hover:border-[#979BB2]': !focused && valid
                    },
                    {
                        'border-[#F9F9F9]': !focused && valid && noBorder
                    },
                    {
                        'border-[#C0C2CD]': !focused && valid && !noBorder
                    },

                    {
                        'cursor-not-allowed': disabled
                    }
                ]"
                :style="{ borderTopColor: noBorder ? 'transparent' : '' }">
                <div v-if="$slots.prefix" class="select-none font-medium"><slot name="prefix"></slot></div>
                <div class="relative flex items-center w-full h-full">
                    <input
                        v-if="useTrim"
                        ref="baseInput"
                        class="w-full h-full relative disabled:text-[var(--sp-text-third)] disabled:cursor-not-allowed placeholder:text-[var(--sp-text-third)] bg-transparent border-none"
                        v-bind="customProps"
                        v-model.trim="internalValue"
                        @keydown.enter="$emit('enter')"
                        @focus="
                            () => {
                                $emit('focus');
                                focused = true;
                            }
                        "
                        @blur="handleBlur"
                        @keypress="checkInputNumber"
                        @input="inputHandler" />
                    <input
                        v-else
                        ref="baseInput"
                        class="w-full h-full relative disabled:text-[var(--sp-text-third)] disabled:cursor-not-allowed placeholder:text-[var(--sp-text-third)] bg-transparent border-none"
                        v-bind="customProps"
                        v-model="internalValue"
                        @keydown.enter="$emit('enter')"
                        @focus="
                            () => {
                                $emit('focus');
                                focused = true;
                            }
                        "
                        @blur="handleBlur"
                        @keypress="checkInputNumber"
                        @input="inputHandler" />
                    <div
                        class="sp-input__status-icons flex items-center"
                        :class="(showResetButton && internalValue?.length) || type == 'password' ? 'right-3' : 'right-1'"
                        @click="clickIcon">
                        <base-icon v-if="showTypingSvg && showEditingIcon" class="h-4 fill-[var(--sp-text-panel)]" name="buttonPencil" />
                        <base-loader v-if="showLoadingSpinner" size="xsmall" class="opacity-50" />
                        <base-icon
                            v-if="type == 'password'"
                            class="fill-[var(--sp-primary-hover)]"
                            :name="[typeInput == 'password' ? 'eyeVisible' : 'eyeNotVisible']"
                            viewBox="0 0 24 24"
                            style="height: 20px" />
                    </div>
                    <span
                        v-if="(placeholder && !labelName && (!placeholderHideNotEmpty || !internalValue?.length ? true : false)) || (placeholder && !internalValue?.length)"
                        class="sp-input__placeholder text-sm left-0"
                        :class="[
                            { active: internalValue || focused }
                            // , noBorder ? 'left-0' : 'left-4'
                        ]">
                        <slot name="placeholderIcon"></slot>
                        {{ placeholder || $t('inputs.search') }}
                    </span>
                    <BaseIcon
                        v-if="iconName"
                        class="sp-input__icon absolute top-[50%] translate-y-[-50%] h-[24px] w-[24px] fill-[#C0C2CD]"
                        :class="[noBorder ? 'left-0' : 'left-3']"
                        :name="iconName"
                        :style="positionIcon" />

                    <BaseButton v-if="showResetButton && internalValue?.length" variant="text" color="third" @click="resetInput" size="xs">
                        <template #icon>
                            <BaseIcon name="Clouse" class="h-[24px]"></BaseIcon>
                        </template>
                    </BaseButton>
                </div>
            </div>
        </label>
    </div>
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';

import BaseLoader from '@/components/ui/BaseLoader.vue';
import { debounce } from '@/helpers';

export default {
    name: 'BaseInputStyled',
    components: {
        BaseIcon,
        BaseLoader,
        BaseButton
    },
    props: {
        noOnlyBorder: { type: Boolean, default: false },
        value: { type: [String, Number], default: '' },
        type: { type: String, default: 'text' },
        name: { type: String },
        labelName: { type: String },
        labelFontSize: { type: String, default: '14px' },
        labelFontColor: { type: String, default: '#131416' },
        labelFontWeight: { type: String, default: '400' },
        noBorder: {
            type: Boolean,
            default: false
        },
        placeholder: { type: String },
        placeholderHideNotEmpty: { type: Boolean, default: false },
        autofocus: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        variant: {
            type: String
        },
        inputWidth: {
            type: String,
            default: ''
        },
        debounceTime: { type: Number, default: 600 },
        autocomplete: { type: String, default: 'off' },
        showResetButton: { type: Boolean, default: false },
        inLine: { type: Boolean, default: false },
        spaceBetween: { type: Boolean, default: false },
        iconName: { type: String, default: '' },
        showLoadingSpinner: { type: Boolean, default: false },
        showTypingSvg: { type: Boolean, default: false },
        hideCalendarIcon: { type: Boolean, default: false },
        maxlength: { type: Number },
        valid: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: false
        },
        size: {
            type: [String, Number],
            default: '44'
        },
        useTrim: {
            type: Boolean,
            default: true
        },
        minNumber: {
            type: [Number, null],
            default: null
        },
        checkValidInteger: {
            type: Function,
            default: () => {}
        },
        isInvalidInteger: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:value', 'input', 'focus', 'blur', 'debounceStarted', 'debounceFinished', 'enter'],
    data() {
        return {
            // debounce: null,
            focused: false,
            showEditingIcon: false,
            internalValue: this.value,
            typeInput: this.type
        };
    },
    watch: {
        value(newValue) {
            if (this.focused) return;
            if (this.internalValue !== newValue) {
                this.internalValue = newValue;
            }
        },
        internalValue(newValue, oldValue) {
            if (newValue !== oldValue && newValue !== this.value) {
                this.showEditingIcon = true;
                this.$emit('debounceStarted');
                this.handleInput();
            }
        }
    },
    computed: {
        customProps() {
            return {
                class: [this.variant, 'sp-input__input', this.resetButton, { hideCalendarIcon: this.hideCalendarIcon }, { 'bg-inherit': !this.valid }],
                type: this.typeInput,
                name: this.name,
                min: this.minNumber,
                autocomplete: this.autocomplete,
                disabled: this.disabled,
                maxlength: this.maxlength,
                style: this.getStyleInput
            };
        },
        positionIcon() {
            if (this.inputWidth) return `right:${this.inputWidth}`;
            return `right:100%`;
        },
        resetButton() {
            if (this.showResetButton) return 'reset-btn';
            return '';
        },
        minFieldHeight() {
            return (parseInt(this.size) && parseInt(this.size) - (this.noBorder ? 4 : 2) + 'px') || '44px';
        },
        getStyleInput() {
            const styles = {
                width: this.inputWidth,
                minHeight: this.minFieldHeight
            };

            if (this.noOnlyBorder) styles.border = 'none';
            return styles;
        }
    },
    methods: {
        focusOnInput() {
            this.$refs.baseInput.focus();
        },
        focus() {
            this.$refs.baseInput.focus();
        },
        selectInput() {
            this.$refs.baseInput.select();
        },

        resetInput() {
            this.internalValue = this.type === 'number' ? null : '';
            this.$emit('update:value', this.internalValue);
            this.focusOnInput();
        },
        setInputValue(value) {
            this.internalValue = value;
            this.$emit('update:value', this.internalValue);
            this.$emit('input', this.internalValue);
        },
        handleBlur() {
            if (this.isInvalidInteger) return;
            if (this.type === 'number' && this.internalValue === '') {
                this.internalValue = null;
            }
            this.$emit('blur', this.internalValue);
            this.focused = false;
        },
        checkInputNumber(event) {
            if (this.type !== 'number') return;

            let value = event.target.value;
            const isNumber = /^[-\d.]$/.test(event.key);
            const canEnterMinus = !(this.minNumber >= 0 && this.minNumber !== null && event.key === '-');
            const isInvalidDotInput = (event.key === '.' && value === '') || (event.key === '.' && value.includes('.') && value[value.length - 1] === '.');

            if (!isNumber || isInvalidDotInput || !canEnterMinus) event.preventDefault();
        },
        clickIcon() {
            if (this.typeInput == 'password' && this.type == 'password') {
                this.typeInput = 'text';
            } else if (this.typeInput == 'text' && this.type == 'password') {
                this.typeInput = 'password';
            }
        },
        inputHandler(event) {
            this.checkValidInteger(event.target.value);
        }
    },
    mounted() {
        if (this.autofocus) this.focusOnInput();
    },
    created() {
        // We get the debounceTime value from the props
        const debounceTime = this.debounceTime;

        // We create the handleInput function with the required delay
        this.handleInput = debounce(function () {
            if (this.isInvalidInteger) return;
            if (!this.internalValue && this.internalValue !== 0 && this.type === 'number') {
                this.internalValue = null;
            }
            this.$emit('update:value', this.internalValue);
            this.$emit('input', this.internalValue);
            this.showEditingIcon = false;
            this.$emit('debounceFinished');
        }, debounceTime);
    }
};
</script>

<style lang="scss" scoped>
.in-line {
    &.space-between .sp-input__label {
        justify-content: space-between;
    }
}
.sp-input__label-noborder {
    transition: transform 100ms;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(70%);
    z-index: 1;
}
.focused .sp-input__label-noborder,
.sp-input_has-value .sp-input__label-noborder {
    transform: translateY(0);
    left: 0;
    z-index: 1;
}

.has-icon {
    .sp-input__input {
        padding-left: 40px;
    }
}
.has-icon.no-border {
    .sp-input__input {
        padding-left: 28px;
    }
}

.has-icon {
    .sp-input__placeholder {
        left: 40px;
    }
}
.has-icon.no-border {
    .sp-input__placeholder {
        left: 28px;
    }
}

.sp-input__placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #979bb2;
    transition:
        transform 250ms ease-out,
        top 250ms ease-out,
        font-size 250ms ease-out;
    pointer-events: none;
    // z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

.sp-input_has-value .sp-input__placeholder {
    top: 0;
    transform: translateY(-55%);
    font-size: 0.7rem;
    line-height: 1rem;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 60%);
    border-radius: 4px;
    // padding: 2px;
    color: var(--border-column-hover);
}

.sp-input_has-value .sp-input__placeholder {
    color: #131416;
}

.sp-input__status-icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

label {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.15s opacity cubic-bezier(0.4, 0, 0.2, 1);
}

input {
    outline: none;
}

input.borderBottom {
    border: none;
    border-bottom: 2px solid;
    border-color: #d7d7d7;
    background-color: inherit;
    border-radius: 0px;
}

input.hideCalendarIcon[type='date']::-webkit-inner-spin-button,
input.hideCalendarIcon[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

@-moz-document url-prefix() {
    input.hideCalendarIcon[type='date'] {
        letter-spacing: -0.5px;
        width: 110px;
        clip-path: inset(0 1.8em 0 0);
    }
}
</style>
