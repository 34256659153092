import { isPrimitive, hasProperty, valueType } from './common';

let transformConjunction = cond => {
    switch (cond) {
        case 'and':
            return '&&';
        case 'or':
            return '||';
        default:
            break;
    }
};
let generateCondition = (conditionObg, targetSample) => {
    if (conditionObg.field === '#has_badges') {
        conditionObg.field = 'has_badges';
    }
    if (conditionObg.field == '' || conditionObg.value === '' || conditionObg.value === undefined || conditionObg.value === null || !hasProperty(targetSample, conditionObg.field)) {
        // conditionObg.field === '#has_badges' ||
        return true;
    }
    if (!Object.prototype.hasOwnProperty.call(conditionObg, 'value') || conditionObg.value == null) {
        // console.log('value is null or not set');
        switch (conditionObg.comparator) {
            case 'empty':
                return `(!target.${conditionObg.field} || target.${conditionObg.field} == null || target.${conditionObg.field} == 'null' || target?.${conditionObg.field}?.length == 0)`;
            case 'not empty':
                return `(target?.${conditionObg.field} !== null && target?.${conditionObg.field} !== 'null' && target?.${conditionObg.field}?.length !== 0)`;
        }
    }

    if (typeof conditionObg.value === 'boolean') {
        // console.log('value is boolean');
        switch (conditionObg.comparator) {
            case '=':
                return `(target?.${conditionObg.field} === ${conditionObg.value})`;
            case '<>':
                return `(target?.${conditionObg.field} !== ${conditionObg.value})`;
            default:
                console.warn('local filter error, value is boolean');
                return true;
        }
    }

    if (isPrimitive(conditionObg.value)) {
        let validStr = String(conditionObg.value)?.replaceAll("'", '');
        // console.log('value is Primitive');
        const dateObj = getDatesByPeriod(conditionObg.value);
        switch (conditionObg.comparator) {
            case '=':
                return `(target?.${conditionObg.field} == '${conditionObg.value}')`;
            case '<>':
                return `(target?.${conditionObg.field} != '${conditionObg.value}')`;
            case '>':
                return `(target?.${conditionObg.field} > '${conditionObg.value}')`;
            case '>=':
                return `(target?.${conditionObg.field} >= '${conditionObg.value}')`;
            case '<':
                return `(target?.${conditionObg.field} < '${conditionObg.value}')`;
            case '<=':
                return `(target?.${conditionObg.field} <= '${conditionObg.value}')`;
            case 'like':
                return `(target?.${String(conditionObg.field)}?.replaceAll("'", "")?.toUpperCase()?.includes('${validStr}'?.toUpperCase()))`;
            case 'period':
                return `(target?.${conditionObg.field} >= '${dateObj.start}' && target?.${conditionObg.field} <= '${dateObj.end}')`;

            default:
                console.warn('local filter error, value is Primitive');
                return true;
        }
    } else if (Array.isArray(conditionObg.value)) {
        // console.log('values is Array of value', targetSample[conditionObg?.field][0]);
        // if(!targetSample[conditionObg?.field] || !Array.isArray(targetSample[conditionObg?.field]) || targetSample[conditionObg?.field]?.length > 1){
        //   return false
        // }
        let targetValueType = valueType(targetSample[conditionObg?.field]);
        let strVal = '';
        if (isPrimitive(conditionObg.value?.[0])) {
            strVal = conditionObg.value;
        } else {
            strVal = conditionObg.value.map(el => el.id);
        }
        switch (conditionObg.comparator) {
            case 'in list':
                if (targetValueType === 'primitive') {
                    return `window.compareArraysContainsAnyValues([target.${conditionObg.field}], ${JSON.stringify(strVal)})`;
                } else if (targetValueType === 'array') {
                    if (isPrimitive(targetSample[conditionObg?.field][0])) {
                        return `window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el), ${JSON.stringify(strVal)})`;
                    } else {
                        return `window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el?.id), ${JSON.stringify(strVal)})`;
                    }
                } else if (targetValueType === 'object') {
                    return `window.compareArraysContainsAnyValues([target.${conditionObg.field}?.id], ${JSON.stringify(strVal)})`;
                } else return null;
            case 'not in list':
                if (targetValueType === 'primitive') {
                    return `!window.compareArraysContainsAnyValues([target.${conditionObg.field}], ${JSON.stringify(strVal)})`;
                } else if (targetValueType === 'array') {
                    if (isPrimitive(targetSample[conditionObg?.field][0])) {
                        return `!window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el), ${JSON.stringify(strVal)})`;
                    } else {
                        return `!window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el?.id), ${JSON.stringify(strVal)})`;
                    }
                } else if (targetValueType === 'object') {
                    return `!window.compareArraysContainsAnyValues([target.${conditionObg.field}?.id], ${JSON.stringify(strVal)})`;
                } else return null;
            default:
                break;
        }
    } else {
        // console.log('values is Object');
        switch (conditionObg.comparator) {
            case '=':
                return `(target?.${conditionObg.field}?.id === '${conditionObg.value?.id}')`;
            case '<>':
                return `(target?.${conditionObg.field}?.id !== '${conditionObg.value?.id}')`;
            case 'between':
                return `(target?.${conditionObg.field} >= '${conditionObg.value.start}' && target?.${conditionObg.field} <= '${conditionObg.value.end}')`;
            case 'in list':
                return `window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el?.id), [${JSON.stringify(conditionObg.value?.id)}])`;
            case 'not in list':
                return `!window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el?.id), [${JSON.stringify(conditionObg.value?.id)}])`;
            default:
                console.warn('uncorrect value of filter');
                return 'value is Object';
        }
    }
};
let generateConditionGroup = (rulesSet, conjunction, targetSample, level = null) => {
    let resalt = '';
    if (Array.isArray(rulesSet)) {
        rulesSet.forEach((el, index) => {
            if (level) {
                resalt = resalt + 'return ';
                level = false;
            }
            if (el?.type === 'conjunction') {
                resalt = resalt + generateConditionGroup(el.conditions, el.operator, targetSample, false);
            } else {
                resalt = resalt + generateCondition(el, targetSample);
            }
            if (index !== rulesSet.length - 1 && resalt !== 'return ') {
                resalt = resalt + transformConjunction(conjunction);
            }
        });
    }
    return resalt;
};

export function checkConditions(target, conditionsSet) {
    if (!conditionsSet?.conditions || conditionsSet.conditions?.length < 1) {
        return true;
    }
    let conditions = JSON.parse(JSON.stringify(conditionsSet));
    let conditionStr = generateConditionGroup(conditions.conditions, conditions.operator, target, true);
    //  console.log("conditionStr",conditionStr);
    let fun = new Function('target', conditionStr);
    return fun(target);
}

export function applyFilter(target, filterset) {
    // console.log(target, filterset);
    if (!filterset || filterset?.conditions?.length <= 0 || !target || target?.length <= 0) {
        return target;
    } else {
        // console.warn('applyFilter',filterset,target?.length);
        return target.filter(el => checkConditions(el, JSON.parse(JSON.stringify(filterset))));
        // console.log(res);
        // return target;
    }
}

export function getFilterFields(filterset) {
    let res = {};

    function find(filterSet) {
        if (!Array.isArray(filterSet)) {
            return;
        }
        filterSet.forEach(element => {
            if (element?.type === 'condition') {
                res[element.field] = element.value;
            } else if (element.type === 'conjunction') {
                find(element?.conditions);
            } else {
                console.log('not find');
            }
        });
    }

    if (filterset) {
        find(filterset);
    }
    return res;
}

export function getDatesByPeriod(period) {
    function timestamp(date) {
        return date.getTime();
    }

    const today = new Date();
    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    function calculateDates(offset) {
        const resultDate = new Date(today);
        resultDate.setDate(today.getDate() + offset);
        const startOfResultDate = new Date(resultDate);
        startOfResultDate.setDate(resultDate.getDate() - resultDate.getDay());
        const endOfResultDate = new Date(startOfResultDate);
        endOfResultDate.setDate(startOfResultDate.getDate() + 6);
        return { start: timestamp(startOfResultDate), end: timestamp(endOfResultDate) };
    }

    if (period === 'today') {
        return { start: timestamp(startOfDay), end: timestamp(today) };
    } else if (period === 'yesterday') {
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return {
            start: timestamp(new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate())),
            end: timestamp(yesterday)
        };
    } else if (period === 'tomorrow') {
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        return {
            start: timestamp(new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate())),
            end: timestamp(tomorrow)
        };
    } else if (period === 'this_week') {
        return calculateDates(0);
    } else if (period === 'last_week') {
        return calculateDates(-7);
    } else if (period === 'this_month') {
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        return { start: timestamp(startOfMonth), end: timestamp(endOfMonth) };
    } else if (period === 'last_month') {
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const startOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
        const endOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
        return { start: timestamp(startOfLastMonth), end: timestamp(endOfLastMonth) };
    } else if (period === 'this_year') {
        const startOfYear = new Date(today.getFullYear(), 0, 1);
        const endOfYear = new Date(today.getFullYear(), 11, 31);
        return { start: timestamp(startOfYear), end: timestamp(endOfYear) };
    } else if (period === 'last_year') {
        const lastYear = new Date(today.getFullYear() - 1, 0, 1);
        const startOfLastYear = new Date(lastYear.getFullYear(), 0, 1);
        const endOfLastYear = new Date(lastYear.getFullYear(), 11, 31);
        return { start: timestamp(startOfLastYear), end: timestamp(endOfLastYear) };
    } else if (period === 'next_week') {
        return calculateDates(7);
    } else if (period === 'next_month') {
        const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        const startOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1);
        const endOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0);
        return { start: timestamp(startOfNextMonth), end: timestamp(endOfNextMonth) };
    } else if (period === 'next_year') {
        const nextYear = new Date(today.getFullYear() + 1, 0, 1);
        const startOfNextYear = new Date(nextYear.getFullYear(), 0, 1);
        const endOfNextYear = new Date(nextYear.getFullYear(), 11, 31);
        return { start: timestamp(startOfNextYear), end: timestamp(endOfNextYear) };
    } else if (period === 'this_quarter') {
        return getQuarterDates(today, timestamp);
    } else if (period === 'last_quarter') {
        const lastQuarter = new Date(today);
        lastQuarter.setMonth(today.getMonth() - 3);
        return getQuarterDates(lastQuarter, timestamp);
    } else if (period === 'next_quarter') {
        const nextQuarter = new Date(today);
        nextQuarter.setMonth(today.getMonth() + 3);
        return getQuarterDates(nextQuarter, timestamp);
    } else {
        return null;
    }
}

function getQuarterDates(date, timestamp) {
    const quarterStartMonth = Math.floor(date.getMonth() / 3) * 3;
    const startOfQuarter = new Date(date.getFullYear(), quarterStartMonth, 1);
    const endOfQuarter = new Date(date.getFullYear(), quarterStartMonth + 3, 0);
    return { start: timestamp(startOfQuarter), end: timestamp(endOfQuarter) };
}
