<template>
    <div class="flex max-w-full h-full items-center px-2">
        <ol class="flex items-center max-w-full" v-if="instanceBreadcrumb">
            <breadcrumb-item
                v-for="(rout, key) in instanceBreadcrumb"
                :key="key"
                :item="rout"
                :last="key === instanceBreadcrumb.length - 1"
                :activeColor="activeColor"></breadcrumb-item>
        </ol>
    </div>
</template>

<script>
import BreadcrumbItem from './BreadcrumbItem.vue';
import { mapState } from 'pinia';
import { useBreadcrumbsStore } from '@/stores/breadCrumbStore';
export default {
    name: 'BreadcrumbComponent',
    components: { BreadcrumbItem },
    props: {
        entityId: {
            type: String
        },
        instanceId: {
            type: String,
            requery: true
        },
        activeColor: {
            type: String,
            default: '#6e6e6e'
        }
    },
    emits: [],
    data() {
        return {};
    },
    computed: {
        ...mapState(useBreadcrumbsStore, ['breadcrumbs']),
        instanceBreadcrumb() {
            if (!this.breadcrumbs || !this.instanceId) return null;
            const breadcrumbs = this.breadcrumbs[this.instanceId];
            if (breadcrumbs) return breadcrumbs;
            else return null;
        }
    },
    methods: {},
    watch: {},
    created() {},
    mounted() {},
    unmounted() {}
};
</script>
<style scoped></style>
