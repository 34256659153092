<template>
    <!-- v-tooltip.top="item.name" -->
    <div class="flex select-none items-center cursor-pointer py-1.5 px-4 border-b border-b-[#EAEBEE] gap-2.5 hover:bg-[#519DF50F]">
        <div
            v-if="['CRMDeal', 'task'].includes(props.entityName)"
            class="min-w-[36px] min-h-[36px] rounded-full flex items-center justify-center"
            :style="{
                background: entityNameToIcon(props.entityName)?.background
            }">
            <BaseIcon
                :name="entityNameToIcon(props.entityName)?.icon"
                class="h-[24px]"
                :style="{
                    fill: entityNameToIcon(props.entityName)?.color
                }"></BaseIcon>
        </div>
        <BaseAvatar v-else :user="props.item" :showInformation="false" :widthPic="36" :heightPic="36" :editable="false"></BaseAvatar>
        <span class="truncate" v-tooltip="textTruncated ? props.item.name : null" ref="foundElement">{{ props.item.name }}</span>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import BaseIcon from '@/components/ui/BaseIcon';
import BaseAvatar from '@/components/ui/BaseAvatar.vue';

const props = defineProps({
    item: {
        type: Object,
        default: () => ({})
    },
    entityName: { type: String }
});

const entityNameToIcon = type => {
    const icons = {
        CRMDeal: { icon: 'Deal2', color: '#C0C2CD', background: '#F9F9F9' },
        task: { icon: 'task-01', color: '#EE87B2', background: '#FFF4F8' }
    };
    return icons[type];
};

function isTextTruncated(element) {
    return element.scrollWidth > element.clientWidth;
}

const foundElement = ref(null);
const textTruncated = ref(false);

onMounted(() => {
    if (foundElement.value) {
        textTruncated.value = isTextTruncated(foundElement.value);
    }
});
</script>
