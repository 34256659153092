<template v-if="name">
    <svg
        v-if="path"
        class="svg-icon"
        :class="$attrs.class || 'base-icon'"
        :style="$attrs.style"
        :viewBox="iconViewBox"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="base-icon"
        stroke-width="1.5"
        v-html="path"></svg>
    <inline-svg v-else :src="getItemPath(name)" width="24" height="24" aria-label="My image" data-testid="base-icon" :disableServerRequests="true"></inline-svg>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import icons from '../../icons';

export default {
    props: ['name', 'viewBox'],
    components: {
        InlineSvg
    },

    data() {
        return {
            // path: icons[this.name],
        };
    },
    methods: {
        // getItemPath(code){
        //   console.log(`${svg}${code}.svg`);
        //   return `${svg}#${code}`
        // }
        getItemPath(code) {
            if (code) {
                return `https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/${code}.svg`;
                // return `https://cdn.jsdelivr.net/npm/twemoji@11.0.1/2/svg/${code}.svg`
                // return `https://twemoji.maxcdn.com/v/latest/svg/${code}.svg`
            } else return '';
        }
    },
    computed: {
        path() {
            return icons[this.name];
        },

        iconViewBox() {
            return this.viewBox ? this.viewBox : '0 0 24 24';
        }
    },
    created() {}
};
</script>
<style lang="scss" scoped>
.active {
    transform: rotateZ(90deg);
}
.svg-icon {
    min-width: 14px;
}
</style>
