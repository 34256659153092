<template>
    <BaseModalWindow v-if="getComponent?.component && !getComponent?.modal" v-bind="getComponent.modalProps" @closeModalWindow="closeModal" :closeOnClickOutside="true" class="z-100">
        <component :is="openComponent.component" @close="closeModal" v-bind="openComponent.props" v-on="openComponent.events" />
    </BaseModalWindow>
    <component v-if="getComponent?.modal" :is="openComponent.component" @close="closeModal" v-bind="openComponent.props" v-on="openComponent.events" />
</template>

<script>
// import baseCard from "@/components/BaseCard.vue";
import { useMultiModalFormStore } from '@/stores/multiModalForm';
import { mapActions, mapState } from 'pinia';
import BaseModalWindow from '@/components/ui/BaseModalWindow.vue';

export default {
    name: 'BaseOpenComponent',
    components: { BaseModalWindow },
    props: {},
    emits: [],
    data() {
        return {};
    },
    computed: {
        ...mapState(useMultiModalFormStore, ['openComponent']),
        getComponent() {
            return this.openComponent;
        }
    },
    methods: {
        ...mapActions(useMultiModalFormStore, ['closeOpenComponent']),
        closeModal() {
            this.closeOpenComponent();
        }
    },
    watch: {},
    created() {},
    updated() {},
    mounted() {}
};
</script>
