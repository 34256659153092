<template>
    <div class="header-search relative overflow-hidden">
        <BaseButton color="third" variant="text" size="sm" @click="handleShowSearchForm" v-tooltip:bottom="$t('tooltip.search')">
            <template #icon>
                <BaseIcon name="search-01" class="h-[24px] w-[24px]"></BaseIcon>
            </template>
        </BaseButton>

        <BaseModalWindow
            v-if="searchModalVisible"
            @closeModalWindow="handleSearchModalClose"
            horizontalAlign="center"
            verticalAlign="top"
            class="flex pb-4"
            :class="[isMobile ? 'pt-[25px]' : 'bg-transparent pt-[88px]']"
            :closeOnClickOutside="true"
            borderRadius="12px"
            ><div
                class="search-form__wrapper border border-[#EAEBEE] rounded-[12px] max-h-[702px]"
                style="box-shadow: 0px 10px 10px 2px #686c6f0f"
                :style="{ minWidth: modalWidth + 'px', maxWidth: modalWidth + 'px' }">
                <SearchForm :entitiesToSearch="entitiesToSearch" :modalWidth="modalWidth" @close="handleSearchModalClose" />
            </div>
        </BaseModalWindow>
    </div>
</template>

<script setup>
import { ref, onMounted, computed, onUpdated } from 'vue';
import BaseModalWindow from '@/components/ui/BaseModalWindow.vue';
import BaseIcon from '@/components/ui/BaseIcon';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import SearchForm from './SearchForm.vue';
import { addEvent } from '../../../analytics/AnalyticsService';
import { useUserStore } from '@/stores/userStore';

const searchModalVisible = ref(false);
const handleShowSearchForm = () => {
    searchModalVisible.value = !searchModalVisible.value;
    if (searchModalVisible.value) {
        addEvent({
            category: 'Navigation Panel',
            action: 'lmbc',
            label: 'Open global search card',
            entity: '',
            instance: 'global search'
        });
    }
};
const handleSearchModalClose = () => {
    addEvent({
        category: 'Global search card',
        action: 'lmbc',
        label: 'Close global search card',
        entity: '',
        instance: 'global search'
    });
    searchModalVisible.value = false;
};

const isMobile = computed(() => useUserStore().isMobile);

const modalWidth = computed(() => {
    let val = 619;
    if (isMobile.value) {
        val = 0.9 * window.innerWidth;
    }
    return val;
});

onMounted(() => {});
onUpdated(() => {});
const entitiesToSearch = ref(['task', 'SmartUser', 'CRMContact', 'CRMAccount', 'CRMLead', 'CRMDeal']);
</script>
<style lang="scss"></style>
