import { vTooltip } from 'floating-vue';

export default {
    ...vTooltip,
    mounted(el, binding) {
        const isOverflowing = el.scrollWidth > el.clientWidth || el.scrollHeight === 0;
        const tooltipValue = binding.value;
        const contentValue = el.textContent.trim();
        const isTooltipDifferent = tooltipValue !== contentValue;
        if (!isOverflowing && !isTooltipDifferent) {
            vTooltip.beforeUnmount(el);
        }
    }
};
