import { fileOpen } from 'browser-fs-access';

export async function openFile() {
    const files = await fileOpen({
        multiple: true,
        excludeAcceptAllOption: true
    });
    let res = await readFile(files);
    let data = res.map(el => ({ ...el, name: el.name.replace(/#/g, '') }));
    return data;
}

async function readFile(files) {
    let res = [];
    const fileToBlob = async file => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
    for (let file of files) {
        let newFile = {
            name: file.name,
            type: file?.type,
            lastModified: file.lastModified,
            data: await fileToBlob(file)
        };
        res.push(newFile);
    }
    return res;
}

export async function readSingleFile(file) {
    const fileToBlob = async file => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
    let newFile = {
        name: file.name,
        type: file?.type,
        lastModified: file.lastModified,
        data: await fileToBlob(file)
    };
    return newFile;
}

export function getFileExtension(fileName) {
    const fileNameParts = fileName.split('.');
    const fileExt = fileNameParts[fileNameParts.length - 1]?.toLowerCase();
    return fileExt;
}

export function isImageFile(file) {
    const fileType = file?.mime_type;
    if (fileType && fileType.startsWith('image/')) return true;

    const fileExtension = getFileExtension(file.name);
    const imgExtension = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg', 'ico', 'heic', 'avif', 'j2k', 'jp2', 'jxr', 'wbmp', 'psd'];
    if (imgExtension.includes(fileExtension)) return true;
    return false;
}

export function isArchiveFile(file) {
    const fileExtension = getFileExtension(file.name);
    const archiveExtension = [
        'zip',
        'rar',
        '7z',
        'gzip',
        'bzip2',
        'xz',
        'tar',
        'cpio',
        'arj',
        'lha',
        'iso',
        'wim',
        'dmg',
        'apk',
        'ipa',
        'cab',
        'z',
        'lz',
        'lzma',
        'lzop',
        'tgz',
        'tbz2',
        'txz',
        'tar.z',
        'cpio.gz',
        'cpio.bz2',
        'cpio.xz'
    ];
    if (archiveExtension.includes(fileExtension)) return true;
    return false;
}

export function isVideoFile(file) {
    const fileExtension = getFileExtension(file.name);
    const videoExtension = [
        'mp4',
        'mov',
        'wmv',
        'avi',
        'flv',
        'mkv',
        'mpg',
        'mpeg',
        'webm',
        '3gp',
        'ts',
        'vob',
        'ogg',
        'divx',
        'xvid',
        'h264',
        'h265',
        'm4v',
        'rm',
        'rmvb',
        'm2ts',
        'mts',
        'f4v',
        'svi'
    ];
    if (videoExtension.includes(fileExtension)) return true;
    return false;
}

export function isSupportedVideoFiles(file) {
    const supportedExtensions = ['mp4', 'webm'];
    if (file) {
        return supportedExtensions.includes(getFileExtension(file.name));
    }
}

export function isPresentationsFile(file) {
    const fileExtension = getFileExtension(file.name);
    const presentationExtension = ['fodp', 'key', 'odp', 'otp', 'pot', 'potm', 'potx', 'pps', 'ppt', 'pptm', 'pptx', 'sda', 'sdd', 'sdp', 'sxi', 'uop'];
    if (presentationExtension.includes(fileExtension)) return true;
    return false;
}

export function isExcelFile(file) {
    const fileExtension = getFileExtension(file.name);
    const excelxtension = ['xls', 'xlsb', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx', 'xwk'];
    if (excelxtension.includes(fileExtension)) return true;
    return false;
}

export async function createThumbnail(file, type) {
    return new Promise(resolve => {
        let img = new Image();
        img.onload = () => {
            let w = 250;
            let h;
            let originalW = img.width;
            let originalH = img.height;
            let thumbnailScale = parseInt(originalW / w, 10);
            h = parseInt(originalH / thumbnailScale, 10);
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            let anw = document.createAttribute('width');
            anw.nodeValue = w;
            let anh = document.createAttribute('height');
            anh.nodeValue = h;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);
            ctx.drawImage(img, 0, 0, w, h);
            canvas.toBlob(function (blob) {
                resolve(blob);
            }, type);
        };
        img.src = file;
    });
}
