<template>
    <div
        class="context-menu__element flex items-center gap-1"
        :class="[
            { 'context-menu__element_readonly': props.data.readonly },
            { 'context-menu__element_checked': props.data.value },
            { 'flex-row-reverse justify-end': props.data?.reverseItemRow }
        ]"
        data-element="menu-element"
        @click="!props.data.readonly ? toggleValue(props.data) : {}">
        <!-- <div class="flex gap-1 items-center"> -->
        <base-icon
            :style="{
                height: `${props.iconHeight}px`
            }"
            name="tick-02"
            class="pointer-events-none"
            :class="[{ 'fill-[#519DF5] ': !props.data.readonly }, { 'fill-none ': !props.data.value }, { 'ml-auto': props.data?.reverseItemRow }]"></base-icon>
        <span v-if="props.data.label" class="pointer-events-none">
            {{ props.data.label }}
        </span>
        <!-- </div> -->
        <base-icon
            class="context-menu__element-icon pointer-events-none"
            :class="{ 'ml-auto': !props.data?.reverseItemRow }"
            v-if="props.data.icon"
            :style="{
                height: `${props.iconHeight}px`,
                fill: props.data.iconColor ? props.data.iconColor : ''
            }"
            :name="props.data.icon"></base-icon>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import { InjectionKeyToggleValue } from '../keys.js';
import BaseIcon from '@/components/ui/BaseIcon.vue';
const props = defineProps({
    data: {
        type: Object,
        default: () => {}
    },
    index: {
        type: Number,
        default: null
    },
    iconHeight: {
        type: Number,
        default: 24
    }
});
const toggleValue = inject(InjectionKeyToggleValue);
</script>
