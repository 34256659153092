<script setup>
import { useSlots, ref, provide, onMounted } from 'vue';
const props = defineProps({
    activeTab: String
});
const slots = useSlots();
const tabTitles = ref(slots.default().map(tab => tab.props?.title));
let selectedTitle = ref(tabTitles.value[0]);
provide('selectedTitle', selectedTitle);
onMounted(() => {
    if (props?.activeTab) {
        selectedTitle.value = props.activeTab;
    }
});
</script>

<template>
    <div class="tabs w-full h-full overflow-auto">
        <ul class="tabs__header">
            <li v-for="title in tabTitles" :key="title" class="tabs__item" :class="{ selected: selectedTitle === title }" @click="selectedTitle = title">
                {{ title }}
            </li>
        </ul>

        <slot />
    </div>
</template>

<style>
.tabs {
    margin: 0 auto;
}
.tabs__header {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    gap: 5px;
}
.tabs__item {
    flex: 1;
    padding: 5px 0;
    /* border-radius: 5px 5px 0 0;
    transition: .4s all ease-out; */
    cursor: pointer;
    user-select: none;
    padding-left: 10px;
}
.tabs__item.selected {
    border-bottom: 2px solid var(--color-modern-primary);
}
.tabs__content {
    min-height: 100px;
    height: 100%;
    border-radius: 0 0 5px 5px;
}
</style>
