import { defineStore } from 'pinia';
//import { addEvent } from "/analytics/AnalyticsService.js";
import { useUserStore } from './userStore';
import { uuid } from 'vue-uuid';

const defaultStick = {
    width: 1200,
    height: 800,
    left: 100,
    top: 10,
    axis: 'both',
    active: true,
    minw: 900,
    minh: 500,
    draggable: true,
    resizable: true,
    parentLim: true,
    snapToGrid: false,
    gridX: 10,
    gridY: 10,
    aspectRatio: false,
    zIndex: 4,
    class: '',
    stickSize: 10,
    radius: true
};

const defaultDialog = {
    title: '',
    buttons: [
        {
            text: 'OK',
            type: 'primary',
            value: true
        },
        {
            text: 'Cancel',
            type: 'danger',
            value: false
        }
    ],
    horizontalAlign: 'center',
    verticalAlign: 'center'
};

const lastActiveComponent = JSON.parse(localStorage.getItem('lastActiveComponent')) || null;

export const useMultiModalFormStore = defineStore('multiModalForm', {
    state: () => ({
        componentItems: [],
        wrapperSize: {
            width: 0,
            height: 0
        },
        dialog: null,
        mainPageContentRect: null,
        lastActiveComponent: lastActiveComponent || null,
        openComponent: null
    }),
    getters: {
        getComponents() {
            return this.componentItems;
        },
        getActiveComponent() {
            return this.componentItems.find(item => item.visible);
        }
    },
    actions: {
        changeLastActiveComponent(stick) {
            this.lastActiveComponent = stick;
            localStorage.setItem('lastActiveComponent', JSON.stringify(stick));
        },
        addComponentItem(item) {
            if (item?.system == false && useUserStore().isMobile) {
                console.warn('mobile or system form');
            }

            if (item?.id && this.componentItems.find(i => i.id === item.id)) {
                const el = this.componentItems.find(i => i.id === item.id);
                this.showComponent(el.id);
                return;
            }
            let compItm = {
                onClose: () => false,
                onHide: () => false,
                btnClick: () => false,
                entityId: '',
                useInFavorite: true,
                useCopyLink: true,
                title: '',
                moreButton: [],
                moreFunction: () => false,
                link: '',
                zindex: 4,
                topProps: {},
                topEvents: {},
                offsetWidth: 0,
                system: false,
                modalProps: {
                    horizontalAlign: 'right',
                    verticalAlign: 'center',
                    class: 'p-4',
                    fullHeight: true
                },
                modalEvents: {},
                events: {},
                id: uuid.v4(),
                ...item,
                stick: {
                    ...this.getDefaultStick(item.stick || {}, item?.offsetWidth || 0)
                },
                visible: true
            };
            this.componentItems.push(compItm);
            return item.id || '';
        },
        removeComponentItem(name) {
            this.componentItems = this.componentItems.filter(item => item.id !== name);
        },
        // showComponent(name, full = false) {
        //   this.componentItems = this.componentItems.map(item => {
        //     if (item.name === name || item?.id === name) {
        //       this.setActiveComponent(item, true);
        //       if (full) {
        //         item.stick.radius = false;
        //         this.resizeToMainPageContent(item);
        //       }
        //       this.changeLastActiveComponent(item.stick);
        //       return {
        //         ...item,
        //         visible: true,
        //       };
        //     }
        //     return item;
        //   });
        // },
        showComponent(name, full = false) {
            this.componentItems.forEach(item => {
                if (item.name === name || item?.id === name) {
                    this.setActiveComponent(item, true);
                    if (full) {
                        item.stick.radius = false;
                        this.resizeToMainPageContent(item);
                    }
                    this.changeLastActiveComponent(item.stick);
                    item.visible = true;
                }
            });
        },
        hideComponent(name) {
            this.componentItems.forEach(item => {
                if (item.name === name || item?.id === name) {
                    this.setActiveComponent(item, false);
                    item.visible = false;
                }
            });
        },
        setActiveComponent(component, active) {
            this.componentItems.forEach(item => {
                if (item.id === component.id) {
                    item.stick.active = active;
                    item.stick.zIndex += 1;
                } else {
                    item.stick.active = false;
                    item.stick.zIndex = 4;
                }
                this.changeLastActiveComponent(item.stick);
            });
        },
        compOnMount(data, component) {
            Object.assign(component, data);
        },
        setWrapperSize(size) {
            this.wrapperSize = size;
        },
        getDefaultStick(optionStick, offsetWidth) {
            const rect = this.mainPageContentRect.getBoundingClientRect();
            const stickActiveComponent = this.componentItems.find(item => item.stick.active && item.visible)?.stick || {};

            let width = optionStick?.width || stickActiveComponent?.width || this.lastActiveComponent?.width || rect?.width || defaultStick.width;
            let height = optionStick?.height || stickActiveComponent?.height || this.lastActiveComponent?.height || rect?.height || defaultStick.height;

            if (width < (optionStick?.minw || defaultStick.minw)) {
                width = defaultStick.minw;
            }
            if (height < defaultStick.minh) {
                height = defaultStick.minh;
            }

            const offset = 15;

            let left = optionStick?.left || stickActiveComponent?.left + offset || this.lastActiveComponent?.left || rect?.left || this.getDefaultLeft(width, offsetWidth);
            let top = optionStick?.top || stickActiveComponent?.top + offset || this.lastActiveComponent?.top || rect?.top || this.getDefaultTop(height);

            if (left < 0) {
                left = 0;
            }

            if (this.wrapperSize.height - (height + 30) < top) {
                top = this.wrapperSize.height - height - 30;
            }
            if (top < 0) {
                top = 0;
            }
            let radius = true;
            if (rect.width === width && rect.height === height && rect.left === left && rect.top === top) {
                radius = false;
            }

            return {
                ...defaultStick,
                ...optionStick,
                width,
                height,
                left,
                top,
                radius
            };
        },
        getDefaultLeft(width, offsetWidth) {
            if (this.wrapperSize.width === 0) return 30;
            return (this.wrapperSize.width - offsetWidth - width) / 2;
        },
        getDefaultTop(height) {
            if (this.wrapperSize.height === 0) return 30;
            return (this.wrapperSize.height - height) / 2;
        },

        openDialog(params) {
            return new Promise((resolve, reject) => {
                this.dialog = {
                    ...defaultDialog,
                    ...params,
                    resolve,
                    reject
                };
            });
        },
        closeDialog(data) {
            if (this.dialog) {
                this.dialog.resolve(data);
                this.dialog = null;
            }
        },
        openComponentFunc(data) {
            this.openComponent = {
                modalProps: {
                    horizontalAlign: 'center',
                    verticalAlign: 'center',
                    fullHeight: false
                },
                ...data
            };
        },
        closeOpenComponent() {
            console.log('closeOpenComponent');
            this.openComponent = null;
        },
        setMainPageContentRect(rect) {
            this.mainPageContentRect = rect;
        },

        resizeToMainPageContent(component) {
            if (this.mainPageContentRect) {
                let rect = this.mainPageContentRect.getBoundingClientRect();
                if (rect.width === component.stick.width && rect.height === component.stick.height && rect.left === component.stick.left && rect.top === component.stick.top) {
                    let rect = this.getDefaultStick(defaultStick, 0);
                    component.methods.resizeAndMove({
                        width: rect.width,
                        height: rect.height,
                        left: rect.left,
                        top: rect.top
                    });
                } else {
                    component.methods.resizeAndMove({
                        width: rect.width,
                        height: rect.height,
                        left: rect.left,
                        top: rect.top
                    });
                }
                component.stick = {
                    ...component.stick,
                    width: rect.width,
                    height: rect.height,
                    left: rect.left,
                    top: rect.top
                };
            }
        }
    }
});
