<template>
    <p class="breadcrumb-item flex items-center overflow-hidden">
        <span class="breadcrumb-point shrink-0" :style="{ 'background-color': last ? activeColor : '' }"></span>
        <span
            v-if="item.disabled || last"
            class="breadcrumb-item truncate"
            :class="{ 'last-item': last }"
            :style="{ color: last ? activeColor : '' }"
            v-tooltip.bottom="item.name"
            >{{ item.name }}</span
        >
        <!-- :style="{ 'max-width': `${maxWidth}px` }" -->
        <router-link v-else :to="item.path" class="mx-1 breadcrumb-item active-item truncate" v-tooltip.bottom="item.name" @click="logClick(item)">
            {{ item.name }}
        </router-link>
    </p>
</template>

<script>
export default {
    name: 'BreadcrumbItem',
    components: {},
    props: {
        item: {
            type: Object
        },
        last: {
            type: Boolean
        },
        // maxWidth: {
        //   type: Number,
        //   default: 150,
        // },
        activeColor: {
            type: String,
            default: '#6e6e6e'
        }
    },
    emits: [],
    data() {
        return {};
    },
    computed: {},
    methods: {
        logClick() {
            this.$analytics.addEvent({
                category: 'Top navigation panel',
                action: 'lmbc',
                label: 'Click to navigate through the project / сhange project or folder',
                entity: 'task',
                instance: this.item.name
            });
        }
    }
};
</script>
<style scoped>
.breadcrumb-item {
    &:first-child {
        .breadcrumb-point {
            display: none;
        }
    }
}
.breadcrumb-item {
    color: #c0c2cd;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    /* max-width: 150px; */
}
.active-item:hover {
    color: rgb(99, 98, 98);
}

.breadcrumb-point {
    width: 6px;
    height: 6px;
    margin: 9px;
    border-radius: 3px;
    background-color: #979bb2;
}
</style>
