<template>
    <time class="clock" v-if="diffInObg">
        <span class="clock__hour">{{ hours }}</span>
        <span class="clock__colon">:</span>
        <span class="clock__minutes">{{ minutes }}</span>
        <span v-if="showSeconds" class="clock__colon">:</span>
        <span v-if="showSeconds" class="clock__minutes inline-block min-w-[18px]">{{ seconds }}</span>
    </time>
</template>
<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
dayjs.extend(utc);
dayjs.extend(duration);

export default {
    name: 'BaseTimer',
    props: {
        startTime: {
            type: Number,
            required: true
        },
        duration: {
            type: Number,
            required: false,
            default: 0
        },
        showSeconds: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            counter: 0,
            ticker: null,
            dateObg: null,
            diffInObg: null
            // minutes: null,
            // hours: null,
            // seconds: null,
        };
    },
    computed: {
        hours() {
            if (this.diffInObg) {
                // return this.diffInObg.hours();
                return Number.parseInt(this.diffInObg.asHours());
            } else return 0;
        },
        minutes() {
            if (this.diffInObg) {
                return this.diffInObg.minutes().toString().padStart(2, '0');
            } else return 0;
        },
        seconds() {
            if (this.diffInObg) {
                return this.diffInObg.seconds().toString().padStart(2, '0');
            } else return 0;
        }
    },
    methods: {
        incTime() {
            let today = dayjs().utc();
            let diffInMs = today.diff(this.dateObg) + Number(this.duration) * 1000;
            this.diffInObg = dayjs.duration(diffInMs);
        }
    },
    created() {
        if (this.startTime) {
            this.dateObg = dayjs(Number(this.startTime)).utc();
            this.ticker = setInterval(() => {
                this.incTime();
            }, 1000);
        }
    },
    updated() {
        this.dateObg = dayjs(Number(this.startTime)).utc();
    },
    unmounted() {
        clearInterval(this.ticker);
    }
};
</script>
