import { useMultiModalFormStore } from '@/stores/multiModalForm';

class EngineModalComponent {
    constructor() {
        this.store = useMultiModalFormStore();
    }

    open(params) {
        return this.store.addComponentItem(params);
    }

    close(name) {
        this.store.removeComponentItem(name);
    }

    show(name) {
        this.store.showComponent(name);
    }

    hide(name) {
        this.store.hideComponent(name);
    }

    hideAll() {
        this.store.componentItems.forEach(item => {
            this.store.hideComponent(item.id);
        });
    }

    getActiveComponent() {
        return this.store.getActiveComponent();
    }

    openDialog(params) {
        return this.store.openDialog(params);
    }

    openComponent(params) {
        return this.store.openComponentFunc(params);
    }
}

export { EngineModalComponent };
