<template>
    <div v-if="modelValue">
        <teleport to="#dialog-container">
            <div class="modal-mask" @click.stop="closeModal">
                <div class="modal-container" :class="dialogClass">
                    <slot></slot>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script>
// import baseCard from "@/components/BaseCard.vue";
export default {
    name: 'BaseDialog',
    components: {},
    props: {
        modelValue: {
            type: Boolean
        },
        inputEvent: {
            type: Event
        },
        contentType: {
            type: String
        },
        contentWidth: {
            type: String
        },
        contentHeight: {
            type: String
        },
        fullHeight: {
            type: Boolean
        },
        top: {
            type: String
        },
        left: {
            type: Number,
            default: 0
        },
        offsetTop: {
            type: String,
            default: '0'
        },
        offsetLeft: {
            type: String,
            default: '0'
        },
        position: {
            type: String,
            default: 'left'
        },
        zindex: {
            type: String,
            default: '1010'
        },
        showOwerlay: {
            type: Boolean,
            default: false
        },
        name: {
            type: String
        },
        left2: {
            type: Number
        },
        showCloseBtn: {
            type: Boolean,
            default: false
        },
        type: {
            type: String
        }
    },
    emits: ['update:modelValue', 'modalClose', 'changeView'],
    data() {
        return {
            documentWidth: 0,
            localEvent: null
        };
    },
    computed: {
        dialogClass() {
            return this.type ? this.type : '';
        }
    },
    methods: {
        showModal(event) {
            console.log('showModal', event);
        },
        closeModal() {
            this.$emit('modalClose');
            this.$emit('update:modelValue', false);
        },
        onKeyDown(evt) {
            if (evt.key === 'Escape') {
                this.closeModal();
            }
        },
        watchForWindowsResize() {
            this.documentWidth = window.innerWidth;
        },
        hasSlot(name) {
            return !!this.$slots[name];
        }
    },
    watch: {},
    mounted() {
        this.documentWidth = window.innerWidth;
        window.addEventListener('resize', this.watchForWindowsResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.watchForWindowsResize);
    }
};
</script>

<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease;
}

.modal-container {
    width: 400px;
    margin: auto;
    /* padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease; */
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.modal-container {
    background: #f3f2f2;
    padding-bottom: 16px;
    padding-top: 16px;
    position: relative;
    padding: 16px;
    overflow: hidden;
    border-radius: 4px;
    box-shadow:
        0 0 0 0 var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
        0 0 0 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
        0 0 0 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
}
.error {
    background-color: rgb(176, 0, 32);
    color: #fff;
}
.success {
    background-color: rgb(76, 175, 80);
    color: #fff;
}
.info {
    background-color: rgb(33, 150, 243);
    color: #fff;
}
</style>
